import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography } from '@material-ui/core';
import Lil from '@loginline/core/core';

export default function Footer() {
  const classes = useStyles();
  const [store] = Lil.useStore();

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Grid className={classes.footer}>
            <Grid>
            </Grid>
            <Grid className={classes.text}>
              <Typography variant="body1" xs={4} md={4}>
                {/* target="_blank" permet d'ouvrir un nouvel onglet  pour ne pas perdre les données du store */}
                <a className={classes.link} href={store.links ? store.links.legalMentions : ''} target="_blank" rel="noopener noreferrer" > Mentions légales </a>-
                <a className={classes.link} href={store.links ? store.links.contact : ''} target="_blank" rel="noopener noreferrer" > Contacts </a>
              </Typography>
            </Grid>
            <Grid item xs={4}>
            </Grid>
            <Grid item xs={4} md={2}>
              {store.logo && <img src={store.logo} alt="logo" className={classes.logo} height={65} />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  footer: {
    zIndex: 1,
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 83,
    position: "relative",
    left: 0,
    bottom: 0,
    width: "100%"
  },
  text: {
    color: theme.palette.light.main
  },
  logo: {
    padding: 8,
    [theme.breakpoints.down('xs')]: {
      marginLeft: "-20px"
    },
  },
  link: {
    textDecoration: "none",
    color: theme.palette.white.main,
    fontSize: 20
  }
}));

