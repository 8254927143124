import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Grid, Box, Typography } from '@material-ui/core';
import Collapse from "@material-ui/core/Collapse";

const collapseComponent = (props) => (
    <tr>
        <td colSpan={12}> {/* put the number of col of your table in this field */}
            <div className={props.className}>
                {props.children}
            </div>
        </td>
    </tr>
)

const TableA7Stocks = (props) => {

    const classes = useStyles();
    const [expanded, setExpanded] = useState("");

    return (
        <TableContainer component={Paper} className={classes.tableRoot}>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {props.titles.map((title, i) => (
                            <StyledTableCell key={i}>{title}</StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {props.rows.map((row, i) => (
                        <React.Fragment key={i}>
                            <StyledTableRow hover>
                                {row.map((column, i) => (
                                    <StyledTableCell key={i}>{column}</StyledTableCell>
                                ))}
                                <StyledTableCell>
                                    <Button className={classes.buttonDetails} onClick={() => setExpanded(row[0])}>
                                        Détail &nbsp; <i className="fas fa-chevron-down" />
                                    </Button>
                                </StyledTableCell>
                            </StyledTableRow>

                            <Collapse
                                in={row[0] === expanded}
                                component={collapseComponent}
                                timeout="auto"
                                unmountOnExit
                            >
                                <Grid item xs={12} sm={12} md={12} key={i}>
                                    <Box className={classes.whiteContainer}>
                                        <Grid container spacing={3}>
                                            {props.titles_details.map((title, index) => {
                                                const value = props.rows_details[i];
                                                return (<Grid item xs={3} key={index}>
                                                    <Box className={classes.categories}>
                                                        <Typography className={classes.subtitleDetails} variant="body1">{title}</Typography>
                                                        <Typography variant="body1">{value[index]}</Typography>
                                                    </Box>
                                                </Grid>)
                                            })}
                                            <Grid item xs={5}></Grid>
                                            <Grid item xs={2}>
                                                <Button className={classes.buttonDetailsBis} onClick={() => setExpanded(false)}>
                                                    Réduire &nbsp; <i className="fas fa-chevron-up" />
                                                </Button>
                                            </Grid>
                                            <Grid item xs={5}></Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Collapse>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 700,
    },
    tableRoot: {
        borderRadius: "10px",
        boxShadow: "none" // Suppresion bordure 
    },
    whiteContainer: {
        //padding: theme.spacing(10),
        paddingTop: 20,
        paddingLeft: 80,
        paddingRight: 80,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(6),
        },
        marginTop: -5,
        marginBottom: 30,
        backgroundColor: theme.palette.light.main,
        //boxShadow: `0px 3px 15px ${makeTransparent(theme.palette.primary.main, 0.5)}`,
        borderRadius: "0px 0px 10px 10px ",
    },
    categories: {
        maxWidth: 210
    },
    buttonDetails: {
        width: 75,
        height: 28,
        fontSize: 14,
        padding: 0,
        borderRadius: 10,
        backgroundColor: 'transparent',
        color: theme.palette.primary.main
    },
    buttonDetailsBis: {
        width: 100,
        height: 28,
        fontSize: 14,
        padding: 0,
        borderRadius: 10,
        backgroundColor: 'transparent',
        color: theme.palette.primary.main
    },
    subtitleDetails: {
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },
    buttonGraphic: {
        width: 272,
        height: 50
    }
}));


const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: 14,
        [theme.breakpoints.down('md')]: {
            fontSize: 10
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: 16
        },
        fontWeight: "bold",
        textAlign: "center"
    },
    body: {
        fontSize: 14,
        [theme.breakpoints.down('md')]: {
            fontSize: 10
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: 16
        },
        borderBottom: "none",
        textAlign: "center"
    },
    root: {
        '&:nth-last-of-type(1)': {
            borderRadius: "0px 10px 10px 0px",
            borderBottom: "none",
        },
        '&:first-of-type': {
            borderRadius: "10px 0px 0px 10px",
            borderBottom: "none",
            //fontWeight: "bold",
            paddingTop: 20
        },
    },
}))(TableCell);

// Alternance de couleur des rangées
const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: '#F5F5F5',
        },
    },
    hover: {
        "&$hover:hover": {
            backgroundColor: "#f2d9e6",
        },
    },
}))(TableRow);



export default TableA7Stocks;