import makeTransparent from "../utils/makeTransparent";

const themeBuilder = ({ primary, customColors }) => ({
    palette: {
        primary: {
            main: primary,
        },
        secondary: {
            main: '#EFACD0',
        },
        tertiary: {
            main: '#F9C5E1',
        },
        dark: {
            main: '#14000A',
        },
        light: {
            main: '#F5F5F5',
        },
        white: {
            main: '#FFFFFF',
        },
        customColors,
    },
    // Définiton des propriétés de la police suivant la maquette
    typography: {
        fontFamily: "Arimo",
        body1: {
            fontSize: 16,
        },
        h1: {
            fontSize: 60,
            fontWeight: "bold",
            textAlign: "center",
        },
        h2: {
            fontSize: 34,
            fontWeight: "bold"
        },
        h3: {
            fontSize: 23,
            fontWeight: "bold"
        },
        h4: {
            fontSize: 20,
            fontWeight: "bold",
            color: primary,
        },
        a: {
            textDecoration: "underline",
            color: primary
        }
    },
    overrides: {
        MuiButton: {
            root: {
                backgroundColor: primary,
                color: 'white',
                "&:hover": { backgroundColor: "white", color: primary },
                width: "176px",
                height: "50px",
                fontSize: "16px",
                fontWeight: "bold",
                border: `3px solid ${primary}`,
                borderRadius: "20px",
                boxShadow: `0px 3px 10px ${makeTransparent(primary, 0.5)}`,
                opacity: 1,
                textTransform: "none",

            },
        },
        MuiInput: {
            root: {
                width: "80%",
                height: "60px",
                boxShadow: `0px 3px 10px ${makeTransparent(primary, 0.5)}`,
                borderRadius: "20px",
                paddingLeft: "4%",
                "&$focused": {
                    boxShadow: `4px 3px 10px 2px ${makeTransparent(primary, 0.5)}`,
                }
            },
        },
        MuiContainer: {
            root: {
                padding: "0px",
                marginLeft: "0px",
                marginRight: "0px",
                width: "100%"
            }
        },
        MuiMenu: {
            paper: {
                boxShadow: 'none',
                padding: '24px'
            },
        },
    }
});

export default themeBuilder;