import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { Grid, Button, Box, Typography, CircularProgress, Icon } from '@material-ui/core';
import Lil from '@loginline/core';
import makeTransparent from '../utils/makeTransparent';

export default function Clients() {

    const classes = useStyles();
    const [store, update] = Lil.useStore();
    const [loading, setLoading] = useState(false);
    const [messageError, setMessageError] = useState('');
    const router = useHistory();

    // Cycle de vie
    useEffect(() => {
        setLoading(true);
        // eslint-disable-next-line
        Lil.method('auth/readInterlocutor', {}, result => update({ interlocutor: result.user })),
            Lil.method('auth/readClients', {
                fields: ['id', 'name'],
            }, result => {
                update({ names: result.map(({id, name}) => ({ id, name })) });
                setLoading(false);
            }).catch(err => { setMessageError('Une erreur est survenue.'); setLoading(false); })
        // eslint-disable-next-line
    }, []);

    return (
        <Box className={classes.clients} style={{ backgroundImage: "url('../connexion-bg.png')", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}>
            <Grid className={classes.content} container spacing={0}>
                <Grid item xs={11} sm={10} md={11} lg={6}>
                    <Box className={classes.logo}>
                        {store.logo && <img src={store.logo} alt="logo" />}
                    </Box>
                    <Box className={classes.whiteContainer}>
                        {store.names && store.names.length > 0 && <Icon className={`fa fa-${loading ? `sync ${classes.rotate}` : "check-circle"} ${classes.icon}`}/>}
                        {store.interlocutor ? <Typography variant="h2" className={classes.titleContainer}>
                            Bonjour {store.interlocutor.name},
                        </Typography> : <CircularProgress/>}
                        {store.names && store.names.map(({id, name}, index) => {
                                return (
                                    <Button onClick={() => { router.push(`/app/home/${id}`) }}
                                        key={index} className={classes.buttonCompany}>{name}
                                        <i className="fas fa-arrow-alt-circle-right">
                                        </i>
                                    </Button>
                                )
                            })
                        }
                        {loading && store.interlocutor && (!store.names || store.names.length === 0) &&
                        <>
                            <Typography variant="h4">Nous récupérons vos informations ...</Typography>
                            <br/>
                            <CircularProgress />
                        </>}
                        {messageError && <text>{messageError}</text>}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    clients: {
        flexGrow: 1,
        backgroundColor: theme.palette.light.main,
        minHeight: '100vh',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    content: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    logo: {
        textAlign: "center",
        marginTop: 30
    },
    whiteContainer: {
        position: "relative",
        backgroundColor: theme.palette.white.main,
        padding: theme.spacing(2),
        marginTop: 30,
        marginBottom: 80,
        borderRadius: 40,
        boxShadow: `0px 3px 15px ${makeTransparent(theme.palette.primary.main, 0.5)}`,
        alignItems: "center",
        textAlign: 'center',
    },
    titleContainer: {
        marginTop: 52,
        marginBottom: 47,
        textAlign: "left",
        marginLeft: "8%",
    },
    buttonCompany: {
        width: "80%",
        height: 60,
        marginBottom: 32,
        justifyContent: 'space-between',
        paddingLeft: '5%',
        paddingRight: '5%',
        fontSize: 20,
    },
    rotate: {
        WebkitAnimation: "spin 2s linear infinite;",
        MozAnimation: "spin 2s linear infinite;",
        animation: "spin 2s linear infinite;",
    },
    icon: {
        color: theme.palette.primary.main,
        position: "absolute",
        top: "15px",
        right: "60px",
    }
}));