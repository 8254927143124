import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Box, Typography, CircularProgress } from '@material-ui/core';
import Lil from '@loginline/core';
import { useParams } from 'react-router-dom';
import TableA7 from '../Components/TableA7';
import Header from '../Components/Header';
import makeTransparent from '../utils/makeTransparent';

export default function Events() {
    const classes = useStyles();

    const [store, update] = Lil.useStore();
    const [loading, setLoading] = useState(true);
    const [loadingEventIncoming, setLoadingEventIncoming] = useState(false);
    const [loadingEventPast, setLoadingEventPast] = useState(false);
    // eslint-disable-next-line
    let { selectedClientId, assetClass } = useParams();

    const title = "Événements";
    const titles = ["Instrument financier", "Nom de l’événement", "Date", "Commentaire"];
    // Format de la date
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

    useEffect(() => {
        // Lecture des données et ajout dans le store du tableau événements à venir 
        Lil.method('auth/readEvents', { id: selectedClientId, eventsInArray: 7 }, result => {
            console.log(result)
            update({
                datasEvents: result.filter(function (todaydate) {
                    return  new Date(todaydate.date_evenement).toJSON().substring(0,10) >= new Date().toJSON().substring(0,10)
                }),
                datasLastMonthEvents: result.filter(function (todaydate) {
                    return new Date(todaydate.date_evenement).toJSON().substring(0,10) < new Date().toJSON().substring(0,10)
                })
            }); setLoading(false)
        },
        );
        Lil.method('auth/readNotif', { id: selectedClientId }, result => { update({ notifications: result }); setLoading(false) });
        // eslint-disable-next-line
    }, [selectedClientId]);

    // Couleur des boutons suivant le statut de l'événement
    const getButtonClass = (nom_evenement) => {
        if (nom_evenement === "Paiement") {
            return classes.buttonGreenBis
        } else if (nom_evenement === "Constatation / Paiement") {
            return classes.buttonRedBis
        } else {
            return classes.buttonYellow
        }
    };

    return (
        <>
            <Header title={title} />
            <Box className={classes.events}>
                <Grid className={classes.table}>
                    <Grid item xs={12} sm={11} md={11} lg={8}>
                        <Typography variant="h2">
                            Événements à venir
                    </Typography>
                        <Box className={classes.blockPurple}></Box>
                        {loading ?
                            <Box className={classes.tableContainer}>
                                <CircularProgress />
                            </Box>
                            :
                            store.datasEvents && store.datasEvents.length > 0 ?
                                <Box className={classes.tableContainer}>
                                    <TableA7 titles={titles} rows={store.datasEvents.map((line, i) => [
                                        line.instrument_id[1],
                                        <Button className={getButtonClass(line.nom_evenement)} 
                                        //                  router.push(`/app/stocksdetails/${selectedClientId}/${assetClass}/${i}/${line.categorie_produit[1]}`)
                                        // onClick={() => { router.push(`/app/stocksdetails/${selectedClientId}/Actions/${i}/${line.categorie_produit[1]}`) }}
                                        >
                                            {line.nom_evenement}</Button>,
                                        new Date(line.date_evenement).toLocaleDateString(undefined, options),
                                        line.commentaire
                                    ])
                                    } />
                                    <Button className={classes.buttonSeeMore} onClick={() => {
                                        setLoadingEventIncoming(true);
                                        Lil.method('auth/readEvents', { id: selectedClientId, eventsInArray: store.datasEvents.length + 5 }, result => {
                                            update({
                                                datasEvents: result.filter(function (todaydate) {
                                                    return new Date(todaydate.date_evenement).toJSON().substring(0,10) >= new Date().toJSON().substring(0,10)
                                                }),
                                            }); setLoadingEventIncoming(false)
                                        })
                                    }}>
                                        {loadingEventIncoming ? <CircularProgress style={{ width: 40 }} /> : <>Voir plus &nbsp; <i className="fas fa-chevron-down" /> </>}
                                    </Button>
                                </Box>
                                :
                                <Box className={classes.tableContainer}>
                                    <Typography variant="h3">Aucun évènement à venir à afficher</Typography>
                                </Box>
                        }
                    </Grid>
                </Grid>
                <Grid className={classes.table}>
                    <Grid item xs={12} sm={11} md={11} lg={8}>
                        <Box className={classes.blockPink}></Box>
                        <Typography variant="h2" className={classes.titles} style={{ position: 'relative' }}>
                            Événements passés
                            </Typography>
                        {loading ?
                            <Box className={classes.tableContainer}>
                                <CircularProgress />
                            </Box>
                            :
                            store.datasLastMonthEvents && store.datasLastMonthEvents.length > 0 ?
                                <Box className={classes.tableContainer}>
                                    <TableA7 titles={titles} rows={store.datasLastMonthEvents.map(line => [
                                        line.instrument_id[1],
                                        <Button className={getButtonClass(line.nom_evenement)}>{line.nom_evenement}</Button>,
                                        new Date(line.date_evenement).toLocaleDateString(undefined, options),
                                        line.commentaire
                                    ])
                                    } />
                                    <Button className={classes.buttonSeeMore} onClick={() => {
                                        setLoadingEventPast(true);
                                        Lil.method('auth/readEvents', { id: selectedClientId, eventsInArray: store.datasLastMonthEvents.length + 5 }, result => {
                                            update({
                                                datasLastMonthEvents: result.filter(function (todaydate) {
                                                    console.log({date : Date(todaydate.date_evenement)})
                                                    return new Date(todaydate.date_evenement).toJSON().substring(0,10) < new Date().toJSON().substring(0,10)
                                                }),
                                            }); setLoadingEventPast(false)
                                        })
                                    }}>
                                        {loadingEventPast ? <CircularProgress style={{ width: 40 }} /> : <>Voir plus &nbsp; <i className="fas fa-chevron-down" /> </>}
                                    </Button>
                                </Box> :
                                <Box className={classes.tableContainer}>
                                    <Typography variant="h3">Aucun évènement passé à afficher</Typography>
                                </Box>
                        }
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

const useStyles = makeStyles(theme => {
    return {
        events: {
            backgroundColor: theme.palette.light.main,
            marginTop: 70,
            minHeight: '100vh'
        },
        table: {
            display: "flex",
            justifyContent: "center"
        },
        tableContainer: {
            position: "relative",
            padding: theme.spacing(2),
            textAlign: 'center',
            marginTop: 30,
            marginBottom: 80,
            backgroundColor: theme.palette.white.main,
            boxShadow: `0px 3px 15px ${makeTransparent(theme.palette.primary.main, 0.5)}`,
            borderRadius: 40,
            alignItems: "center",
            paddingTop: 60,
            paddingLeft: 80,
            paddingRight: 80,
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 10,
                paddingRight: 10
            },
        },
        buttonRed: {
            backgroundColor: "white",
            color: "#FC203A",
            width: "80px",
            height: "28px",
            borderColor: "red",
            "&:hover": { backgroundColor: "white", color: "#FC203A" },
            padding: 0,
            fontSize: "14px",
            border: "2px solid",
            borderRadius: "10px"
        },
        buttonGreen: {
            backgroundColor: "white",
            color: "#30B13E",
            width: "80px",
            height: "28px",
            borderColor: "#30B13E",
            "&:hover": { backgroundColor: "white", color: "#30B13E" },
            padding: 0,
            fontSize: "14px",
            border: "2px solid",
            borderRadius: "10px"
        },
        buttonYellow: {
            backgroundColor: "white",
            color: "#E3AC14",
            width: "80px",
            height: "28px",
            borderColor: "#E3AC14",
            "&:hover": { backgroundColor: "white", color: "#E3AC14" },
            padding: 0,
            fontSize: "14px",
            border: "2px solid",
            borderRadius: "10px"
        },
        buttonRedBis: {
            backgroundColor: "white",
            color: "#FC203A",
            width: "175px",
            height: "28px",
            borderColor: "red",
            "&:hover": { backgroundColor: "white", color: "#FC203A" },
            padding: 0,
            fontSize: "14px",
            border: "2px solid",
            borderRadius: "10px"
        },
        buttonGreenBis: {
            backgroundColor: "white",
            color: "#30B13E",
            width: "175px",
            height: "28px",
            borderColor: "#30B13E",
            "&:hover": { backgroundColor: "white", color: "#30B13E" },
            padding: 0,
            fontSize: "14px",
            border: "2px solid",
            borderRadius: "10px"
        },
        buttonYellowBis: {
            backgroundColor: "white",
            color: "#E3AC14",
            width: "175px",
            height: "28px",
            borderColor: "#E3AC14",
            "&:hover": { backgroundColor: "white", color: "#E3AC14" },
            padding: 0,
            fontSize: "14px",
            border: "2px solid",
            borderRadius: "10px"
        },
        buttonSeeMore: {
            width: 100,
            height: 28,
            fontSize: 14,
            padding: 0,
            marginTop: 35,
            marginBottom: 35,
            borderRadius: 10
        },
        blockPurple: {
            position: "absolute",
            width: "55%",
            height: 400,
            backgroundImage: "url('../../home-picture.jpg')",
            borderRadius: "0px 0px 40px 0px",
            zIndex: 0,
            left: 0,
            top: 530,
            [theme.breakpoints.down('md')]: {
                display: "none"
            }
        },
        titles: {
            marginTop: 30
        },
        blockPink: {
            position: "absolute",
            width: "30%",
            height: "30%",
            backgroundImage: "url('../../connexion-bg.png')",
            borderRadius: "40px 0px 0px 0px",
            zIndex: 0,
            right: 0,
            [theme.breakpoints.down('md')]: {
                display: "none"
            }
        }
    }
});