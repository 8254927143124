import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import TodoList from '../Components/Todos/TodoList';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Demo = (props) => {

  const [todoList, setTodoList] = useState([]);
  const classes = useStyles();

  return (
    <div className="Demo">

      <h1>Démo Material-UI</h1>
      <div className="main-buttons">
        <Button>Se connecter</Button>
        <Button className={classes.myLeftButton} color="primary">Hello World</Button>
      </div>
      <div className="small-buttons" style={{ marginTop: "30px" }}>
        <Button className={classes.thirdButton}>Bouton</Button>
        <Button>Détails</Button>
      </div>
      <button onClick={() => { setTodoList([{ title: 'Todo 1', content: 'Do this', isAccordion: true }, { title: 'Todo 2', content: 'Do that' }, { title: 'Todo 3', content: 'Do this' }]) }}>Fetch todos</button>
      <TodoList todos={todoList} />

      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Expansion Panel 1</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Expansion Panel 2</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      
      <ExpansionPanel disabled>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>Disabled Expansion Panel</Typography>
        </ExpansionPanelSummary>
      </ExpansionPanel>

    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  myLeftButton: {
    marginLeft: "50px",
    backgroundColor: "white",
    border: "3px solid white",
    "&:hover": { backgroundColor: theme.palette.primary.main, color: "white", border: "3px solid white" }
  },
  thirdButton: {
    width: "80px",
    height: "28px",
    fontSize: "14px",
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "10px",
    // Centrer le texte dans le boutton :
    padding: 0,
    marginRight: "50px"
  },
}));

export default Demo;