import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import SynthesisChart from '../Components/SynthesisChart'
import ReactResizeDetector from 'react-resize-detector';
import { useParams } from 'react-router-dom';
import Header from '../Components/Header';
import Lil from '@loginline/core';
import makeTransparent from '../utils/makeTransparent';

export default function Synthesis() {
    const classes = useStyles();
    const title = "Évolution par classe d'actifs" // Anciennement Synthèse des évolutions des montants
    const [width, setWidth] = useState(null);
    const [globalDisplay, setGlobalDisplay] = useState(true);
    const [yearDisplay, setYearDisplay] = useState(false);
    const [monthDisplay, setMonthDisplay] = useState(false);
    const [store, update] = Lil.useStore();
    const assetClasses = store.datasHome ? store.datasHome.map(each => each.name) : [];
    let { selectedClientId } = useParams();
    //const price = (number) => new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(number);


    function formatDate(input) {
        var datePart = input.match(/\d+/g),
            year = datePart[0].substring(2), // get only two digits
            month = datePart[1], day = datePart[2];
        return day + '/' + month + '/' + year;
    }

    useEffect(() => {
        // eslint-disable-next-line
        Lil.method('auth/readHistory', { client_id: parseInt(selectedClientId) },
            result => {
                update({ history: result });
            })
        Lil.method('auth/readNotif', { id: selectedClientId }, result => { update({ notifications: result }) });
    }, [selectedClientId, update]);
    useEffect(() => {
        if (!store.datasHome) { Lil.method('auth/readClientsHome', { id: selectedClientId }, result => { update({ datasHome: result }) }); }
    }, [store.datasHome, update, selectedClientId]);

    const graphGlobal = store.history ?
        store.history.filter(history => new Date(history.date).getTime() <= new Date().getTime()).map(history => ({
            date: history.date,
            formatUpdate: formatDate(history.date),
            ...history.history_lines.reduce((linesObj, line) => ({
                ...linesObj,
                [line.name]: line.montant_valorisation,
                "Total": linesObj.Total + line.montant_valorisation
            }), { "Total": 0 }),
        }))
        :
        [];

    let lastYearDate = ((new Date()).getTime()) - (1000 * 60 * 60 * 24 * 365);
    const graphYear = graphGlobal.filter(element => new Date(element.date).getTime() > lastYearDate);

    let lastMonthDate = ((new Date()).getTime()) - (1000 * 60 * 60 * 24 * 30);
    const graphMonth = graphGlobal.filter(element => new Date(element.date).getTime() > lastMonthDate);

    return (
        <>
            <Header title={title} />

            <Box className="synthesis">
                <Grid className={classes.root} container spacing={0}>
                    <Grid item xs={12} sm={11} md={10} lg={10}>
                        {/* <Box className={classes.title}>
                        Synthèse des évolutions des montants
                    </Box> */}
                        <Box className={classes.whiteContainer}>
                            <Grid className={classes.btnWrapper}>
                                <Button className={classes.whiteBtn}
                                    onClick={() => {
                                        setGlobalDisplay(true);
                                        setMonthDisplay(false);
                                        setYearDisplay(false);
                                    }} >Global</Button>
                                <Button className={classes.whiteBtn}
                                    onClick={() => {
                                        setYearDisplay(true);
                                        setGlobalDisplay(false);
                                        setMonthDisplay(false);
                                    }}>Année</Button>
                                <Button className={classes.whiteBtn}
                                    onClick={() => {
                                        setMonthDisplay(true);
                                        setYearDisplay(false);
                                        setGlobalDisplay(false);
                                    }}>Mois</Button>
                            </Grid>
                            <ReactResizeDetector handleWidth onResize={x => setWidth(x)} />
                            {globalDisplay && (
                                graphGlobal.length > 0 ?
                                    <SynthesisChart width={width} data={graphGlobal.reverse()} assetClasses={assetClasses} />
                                    :
                                    <Typography className={classes.noDataMsg} variant="h3">Aucune données enregistrées pour le moment</Typography>
                            )
                            }
                            {yearDisplay && (
                                graphYear.length > 0 ?
                                    <SynthesisChart width={width} data={graphYear.reverse()} assetClasses={assetClasses} />
                                    :
                                    <Typography className={classes.noDataMsg} variant="h3">Aucune données enregistrées pour le moment</Typography>
                            )
                            }
                                                        {monthDisplay && (
                                graphMonth.length > 0 ?
                                    <SynthesisChart width={width} data={graphMonth.reverse()} assetClasses={assetClasses} />
                                    :
                                    <Typography className={classes.noDataMsg} variant="h3">Aucune données enregistrées pour le moment</Typography>
                            )
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.light.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    title: {
        fontSize: 40,
        fontWeight: "bold",
        textAlign: 'center',
        margin: '45px 0 45px 0'
    },
    whiteContainer: {
        padding: "40px 20px 50px 20px",
        marginTop: 30,
        marginBottom: 80,
        backgroundColor: theme.palette.white.main,
        boxShadow: `0px 3px 15px ${makeTransparent(theme.palette.primary.main, 0.5)}`,
        borderRadius: 40,
        alignItems: "center",
        width: "100%",
        [theme.breakpoints.down('md')]: {
            padding: "40px 10px 20px 10px",
            margin: "30px 0 70px 20px",
            width: "90%",
            height: 650,
        },
        [theme.breakpoints.down('xs')]: {
            padding: "40px 10px 20px 10px",
            margin: 8,
            width: "90%",
            height: 650,
        },
    },
    box: {
        width: "60%"
    },
    whiteBtn: {
        backgroundColor: theme.palette.white.main,
        color: theme.palette.primary.main,
        margin: "0px 0 40px 40px",
        padding: 10,
        width: 100,
        height: 32,
        borderRadius: 10,
        [theme.breakpoints.down('sm')]: {
            margin: 10,
            width: 80,
        },
        "&:focus": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.white.main
        }
    },
    noDataMsg: {
        marginLeft: 40
    },
    btnWrapper: {
        textAlign: 'center'
    }
}));