import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { makeStyles, useTheme } from '@material-ui/core';

const price = (number) => new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(number);

const groupSmall = data => {

  const totalValue = data.reduce((acc, val) => acc + val.value, 0);

  const retainedBig = data.filter(val => !(val.value / totalValue < 0.09));
  const retainedSmall = data.filter(val => val.value / totalValue < 0.09);

  let reagencedData = retainedBig.reduce((acc, val, index) => retainedSmall[index] ? [...acc, val, retainedSmall[index]] : [...acc, val], []);
  if (retainedSmall.length > retainedBig.length) {
    reagencedData.pop();
    const totalToGroupValue = retainedSmall.slice(retainedBig.length - 1).reduce((acc, val) => acc + val.value, 0);
    if (totalToGroupValue !== 0) {
      reagencedData = reagencedData.concat({
        value: totalToGroupValue,
        name: "Reste",
        invest: price(totalToGroupValue)
      })
    }
  }

  return reagencedData;
};

const useStyles = makeStyles(theme => ({
  Pie: {
    margin: "50px 0 0 200px",
    [theme.breakpoints.down('xs')]: {
      display: "none"
    },
    Label: {
      color: "black",
      margin: "500px"
    }
  }
}));

const renderLabel = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx, cy, midAngle, outerRadius,
    fill, percent
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius) * cos;
  const sy = cy + (outerRadius) * sin;
  const mx = cx + (outerRadius + 50) * cos;
  const my = cy + (outerRadius + 50) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333" style={{ fontWeight: "bold" }}>{`${props.name}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#333">{`${props.invest}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={36} textAnchor={textAnchor} fill="#999">
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

export default function PieChartA7(props) {
  const theme = useTheme();
  const colors = theme.palette.customColors || [];
  const classes = useStyles();

  const data = groupSmall(props.name.map((name, index) => {
    // Ici on ne souhaite pas afficher de décimales

    return { name: name, invest: price(props.valo[index]), value: props.valo[index] }
  }));

  return (
    <PieChart width={700} height={500} className={classes.Pie} viewB >
      <Pie isAnimationActive={false}
        data={data}
        dataKey="value"
        cx={'50%'}
        cy={'50%'}
        outerRadius={"50%"}
        fill={theme.palette.primary.main}
        label={renderLabel}
        labelLine={false}
      >
        {
          data.map((entry, index) => <Cell key={index} fill={colors[index % colors.length]} />)
        }
      </Pie>
      {/* <Legend /> */}
    </PieChart>
  );
}