import React, { useState } from 'react';
import { styled } from '@material-ui/core/styles';
import { Menu, MenuItem, AppBar, Button, Divider, Grid, Box, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import HamburgerSpring from 'react-animated-burgers/lib/components/HamburgerSpring/HamburgerSpring';
import { useParams, useHistory } from "react-router-dom";
import Lil from '@loginline/core';

export default function Navbar(props) {

    const classes = useStyles();
    const isMObile = window.innerWidth < 760;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElBis, setAnchorElBis] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        setMenuIsActive(!isMenuActive);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setMenuIsActive(!isMenuActive);
    };

    const handleClickBis = event => {
        setAnchorElBis(event.currentTarget);
    };
    const handleCloseBis = () => {
        setAnchorElBis(null);
    };

    const router = useHistory();
    const [isMenuActive, setMenuIsActive] = useState(false);
    // eslint-disable-next-line
    const [store, update] = Lil.useStore();
    let { selectedClientId } = useParams();
    let clientName = store.client ? store.client[0].name : "";
    

    return (
        <Box className={classes.NavBar}>
            <Grid container spacing={0}>
                <Grid item xs={1} md={10}>
                    <AppBar className={classes.AppBar} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} >
                        <Typography variant="h3" className={classes.clientName}>{clientName}</Typography>
                        <HamburgerSpring className={classes.hamburger} style={{ position: 'absolute', right: '15px', top: "13px" }} isActive={isMenuActive} barColor="white" />
                    </AppBar>
                </Grid>
                {isMObile ? (
                    <Grid item xs={11} md={2} className={classes.logoMobile}>
                        {store.logo && <img src={store.logo} alt="logo" />}
                    </Grid>
                ) : (
                        <Grid item xs={11} md={2}>
                            {store.logo && <img src={store.logo} alt="logo" className={classes.logoDesktop} />}
                        </Grid>
                    )
                }
            </Grid>
            <Typography>
                <Menu className={classes.menu}
                    PaperProps={{ className: classes.submenu }}
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MyMenuItem aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickBis}>
                        Clients
                    </MyMenuItem>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorElBis}
                        keepMounted
                        open={Boolean(anchorElBis)}
                        onClose={handleCloseBis}
                        PaperProps={{ className: classes.submenu }}
                    >
                        {props.clients && (
                            props.clients.map((name) => {
                                return (
                                    <MyMenuItem onClick={() => {
                                        router.push(`/app/home/${name.id}`);
                                        handleCloseBis()
                                        handleClose()
                                    }} key={name.id} >
                                        {name.name}
                                    </MyMenuItem>
                                )
                            })
                        )}
                    </Menu>
                    <Divider light />
                    <MyLink to={`/app/home/${selectedClientId}`}>
                        <MyMenuItem onClick={handleClose}>Accueil</MyMenuItem>
                    </MyLink>
                    <Divider light />
                    <MyLink to={`/app/notifications/${selectedClientId}`}>
                        <MyMenuItem onClick={handleClose}>Notifications {store.notifications ? <Box className={classes.notifications}>{store.notifications.length}</Box> : ''}</MyMenuItem>
                    </MyLink>
                    <Divider light />
                    <MyLink to={`/app/events/${selectedClientId}`}>
                        <MyMenuItem onClick={handleClose}>Évenements</MyMenuItem>
                    </MyLink>
                    <Divider light />
                    <MyLink to={`/app/synthesis/${selectedClientId}`}>
                        <MyMenuItem onClick={handleClose}>Évolution par classe <br /> d'actifs</MyMenuItem>
                    </MyLink>
                    <MyLink to={`/app/myaccount/${selectedClientId}`}>
                        <MyMenuItem onClick={handleClose}><Button className={classes.whiteBtn}>Mon compte</Button></MyMenuItem>
                    </MyLink>
                    <MyMenuItem onClick={handleClose}><Button
                        onClick={() => { props.logoutFunction() }}>Se déconnecter</Button></MyMenuItem>
                </Menu>
            </Typography>
        </Box>
    );
}

const MyMenuItem = styled(MenuItem)({
    fontWeight: 'bold',
    color: 'white',
    padding: '13px 0',
});

const MyLink = styled(Link)({
    textDecoration: "none"
});

const useStyles = makeStyles(theme => ({
    AppBar: {
        background: theme.palette.primary.main,
        borderRadius: '0px 0px 20px 0px',
        boxShadow: 'none',
        color: theme.palette.white.main,
        height: 83,
        width: 307,
        padding: '0 30px',
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        position: 'relative',
    },
    clientName: {
        maxWidth: 180,
        marginLeft: -90
    },
    hamburger: {
        marginLeft: 100
    },
    NavBar: {
        backgroundColor: theme.palette.light.main
    },
    logoMobile: {
        display: 'none'
        // margin: '12px 23px',
        // position: 'absolute',
        // zIndex: 1500,
    },
    logoDesktop: {
        paddingTop: 2

    },
    whiteBtn: {
        backgroundColor: theme.palette.white.main,
        color: theme.palette.primary.main
    },
    menu: {
        background: theme.palette.primary.main,
        width: '307px',
        height: '91vh',
        marginTop: 65,
        borderRadius: '0px 0px 20px 0px',
    },
    submenu: {
        backgroundColor: theme.palette.primary.main,
    },
    link: {
        textDecoration: "none"
    },
    notifications: {
        marginLeft: 20,
        backgroundColor: 'red',
        color: 'white',
        textAlign: 'center',
        borderRadius: 20,
        fontSize: 15,
        width: 22,
        height: 22,
        paddingRight: 2,
        paddingLeft: 2
    }
}))