import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';

// Alternance de couleur des rangées
const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: '#F5F5F5',
        },
    },
}))(TableRow);

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 700,
    },
    tableRoot: {
        borderRadius: "10px",
        boxShadow: "none" // Suppression bordure 
    },
}));


const TableA7 = (props) => {
    const classes = useStyles();

    return (
        <TableContainer component={Paper} className={classes.tableRoot}>
            <Table className={classes.table} aria-label="customized table">
    
                <TableHead>
                    <TableRow>
                        {props.titles.map((title, i) => (
                            <StyledTableCell key={i}>{title}</StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.rows.map((row, i) => (
                        <StyledTableRow key={i}>
                            {row.map((column, i) => (
                                <StyledTableCell key={i}>{column}</StyledTableCell>
                            ))}
                        </StyledTableRow>
                    ))}
                    <StyledTableRow>
                        {props.total && props.total.map((total, i) => (
                            <StyledTableCell key={i}>{total}</StyledTableCell>
                        ))}
                    </StyledTableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: 16,
        fontWeight: "bold",
        textAlign: "center"
    },
    body: {
        fontSize: 16,
        borderBottom: "none",
        textAlign: "center"
    },
    root: {
        '&:nth-last-of-type(1)': {
            borderRadius: "0px 10px 10px 0px",
            borderBottom: "none",
        },
        '&:first-of-type': {
            borderRadius: "10px 0px 0px 10px",
            borderBottom: "none",
            fontWeight: "bold",
            paddingTop: 20
        },
    },
}))(TableCell);

export default TableA7;