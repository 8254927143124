import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Box, Typography } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import Lil from '@loginline/core';
import { Redirect } from "react-router-dom";
import makeTransparent from '../utils/makeTransparent';

export default function Connexion() {
    const [store, update] = Lil.useStore();
    const classes = useStyles();

    // Récupération des données des inputs
    const [accountName, setAccountName] = useState('');
    const [accountPassword, setAccountPassword] = useState('');
    // Initialisation du state pour vérifier si l'utilisateur est connecté et le rediriger vers la page de ses clients
    const [wrongPass, setWrongPass] = useState(false);

    return (
        <Box className={classes.connexion} style={{ backgroundImage: "url('/connexion-bg.png')", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}>
            <Grid className={classes.content} container spacing={0}>
                <Grid item xs={11} sm={10} md={11} lg={6}>
                    <Box className={classes.logo}>
                        {store.logo && <img src={store.logo} alt="logo" />}
                    </Box>
                    <form onSubmit={(e) => {
                        e.preventDefault(); Lil.Account.login(accountName, accountPassword,
                            (result) => {update({userLoggedIn: true})}).catch(e => { setWrongPass(true) })
                    }}>
                        <Box className={classes.whiteContainer}>
                            <Typography variant="h2" className={classes.titleContainer}>
                                Connexion
                        </Typography>
                            <Typography variant="h4" className={classes.inputTitle}>
                                Adresse e-mail
                        </Typography>
                            <Input disableUnderline={true} placeholder="exemple@exemple.com" required={true} autoFocus value={accountName} onChange={event => setAccountName(event.target.value)} />
                            <Typography variant="h4" className={classes.inputTitle}>
                                Mot de passe
                        </Typography>
                            <Input disableUnderline={true} placeholder="Votre mot de passe" required={true} type="password" value={accountPassword} onChange={event => setAccountPassword(event.target.value)} />
                            {wrongPass &&
                                <p style={{ color: '#FF0000' }}>Votre identifiant ou mot de passe est incorrect</p>
                            }
                            <Box>
                                <Button className={classes.buttonConnect} type="submit">
                                    Se connecter
                            </Button>
                            </Box>
                            {store.userLoggedIn && <Redirect to={{ pathname: "/clients", }} />}
                        </Box>
                    </form>
                </Grid>
            </Grid>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    connexion: {
        flexGrow: 1,
        backgroundColor: theme.palette.light.main,
        minHeight: '100vh', // Centre automatiquement le container 
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    content: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    logo: {
        textAlign: "center",
        marginTop: 30
    },
    whiteContainer: {
        backgroundColor: theme.palette.white.main,
        padding: theme.spacing(1),
        marginTop: 30,
        marginBottom: 80,
        borderRadius: 40,
        boxShadow: `0px 3px 15px ${makeTransparent(theme.palette.primary.main, 0.5)}`,
        alignItems: "center",
        textAlign: 'center',
    },
    titleContainer: {
        paddingTop: 50
    },
    inputTitle: {
        textAlign: "left",
        marginLeft: "10%",
        marginBottom: 11,
        marginTop: 40
    },
    buttonConnect: {
        marginTop: 39,
        marginBottom: 48
    },
}));