import React, { useEffect, useState } from 'react';
import { Grid, Button, Box, Typography, CircularProgress, Paper, Snackbar, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Lil from '@loginline/core';
import { useParams, useHistory } from 'react-router-dom';
import Header from '../Components/Header';
import TableA7Stocks from '../Components/TableA7Stocks';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import makeTransparent from '../utils/makeTransparent';

function formatDate(input) {
    const datePart = input.match(/\d+/g);
    if (datePart && datePart.length) {
        const year = datePart[0];
        const month = datePart[1];
        const day = datePart[2];
        return day + '/' + month + '/' + year;
    }
    else {
        return "...";
    }
}

export default function Stocks() {

    const classes = useStyles();
    // eslint-disable-next-line
    const [store, update] = Lil.useStore();
    const [fetching, setFetching] = useState([false, false, false]);
    const [loading, setLoading] = useState(false);
    const [displaySnackbar, setDisplaySnackbar] = useState(false);
    let { selectedClientId, assetClass } = useParams();
    const router = useHistory();

    const [dataStocks, setDataStocks] = useState(store.allDataStocks ? store.allDataStocks[assetClass] : []);

    // Date reporting
    const encoursDateBack = store.client ? store.client[0].date_analyse_encours_customer : " ";
    const encoursDate = encoursDateBack !== false ? "reporting au " + formatDate(encoursDateBack) : " ";

    // Format de la date
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    // Format des nombres
    const price = (number) => new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(number);

    // On récupère les données en les triants suivant la classe d'actif
    useEffect(() => {
        setFetching([true, true, true]);
        Lil.method('auth/readComments', {
            fields: ['id', 'text', 'instrument_id'],
        }, result => {
            update({ commentArray: result });
            setLoading(false);
        });
        Lil.method('auth/readActions', {
            id: selectedClientId,
            fields: ['id', 'true_classe_actif', 'montant_reel_encours_customer', 'montant_valorisation_encours_customer', 'montant_garanti_encours_customer', 'plus_value_souscription_encours_customer', 'coupon_percu_souscription_encours_customer', 'resultat_net_souscription_encours_customer', 'indexation_ids', 'instrument_id', 'date_debut', 'date_fin', 'rendement_coupon_couru_encours_customer', 'rendement_net_souscription_encours_customer', 'categorie_produit', 'rendement_estime', 'nombre', 'derniere_valorisation_encours_customer', 'date_derniere_valorisation', 'montant_garanti_encours_customer', 'commentaire'],
        }, result => {
            const resultData = result.reduce((acc, cv) => {
                if (acc[cv.true_classe_actif[1]]) {
                    return { ...acc, [cv.true_classe_actif[1]]: acc[cv.true_classe_actif[1]].concat(cv) };
                } else {
                    return { ...acc, [cv.true_classe_actif[1]]: [cv] };
                }
            }, {});
            update({
                allDataStocks: resultData,
            });

            const dataStocksFiltered = Object.keys(resultData).filter(key => key === assetClass).map(key => resultData[key]).reduce((acc, cv) => acc.concat(cv), []);
            setDataStocks(dataStocksFiltered);

            Lil.method('auth/readIndexation', {
                indexations_ids: dataStocksFiltered.flatMap(x => x.indexation_ids),
                fields: ['id', 'name'],
            }, result => {
                const indexationArray = [];
                result.forEach(item => indexationArray[item.id] = item.name);
                update({ indexationArray: indexationArray });
                setFetching(f => [f[0], false, f[2]]);
            });
            Lil.method('auth/ReadInstrumentsStocks', {
                instrument_ids: dataStocksFiltered.flatMap(x => x.instrument_id[0]),
                fields: ['id', 'code_isin', 'emetteur_id', 'comments'],
            }, result => {
                const instrumentArray = [];
                result.forEach(item => instrumentArray[item.id] = item);
                update({ instrumentArray: instrumentArray });
                setFetching(f => [f[0], f[1], false]);
            });

            setFetching(f => [false, f[1], f[2]]);
        });
    }, [assetClass, selectedClientId, update]);

    // Somme des montants
    const amount = () => {
        if (dataStocks && dataStocks.length > 0) {
            const reducer = (accumulator, currentValue) => accumulator + currentValue;
            return dataStocks.map(x => x.montant_reel_encours_customer).reduce(reducer);
        }
    }

    // Somme du montant valorisé en jour
    const amountValuedInDays = () => {
        if (dataStocks && dataStocks.length > 0) {
            const reducer = (accumulator, currentValue) => accumulator + currentValue;
            return dataStocks.map(x => x.montant_valorisation_encours_customer).reduce(reducer);
        }
    }

    // Somme du montant garanti
    const guaranteedAmount = () => {
        if (dataStocks && dataStocks.length > 0) {
            const reducer = (accumulator, currentValue) => accumulator + currentValue;
            return dataStocks.map(x => x.montant_garanti_encours_customer).reduce(reducer);
        }
    }

    // Somme +/- Value latente depuis la souscription
    const unrealizedValueSinceSubscription = () => {
        if (dataStocks && dataStocks.length > 0) {
            const reducer = (accumulator, currentValue) => accumulator + currentValue;
            return dataStocks.map(x => x.plus_value_souscription_encours_customer).reduce(reducer);
        }
    }

    // Somme Coupons perçus depuis la souscription 
    const couponsReceivedSinceSubscription = () => {
        if (dataStocks && dataStocks.length > 0) {
            const reducer = (accumulator, currentValue) => accumulator + currentValue;
            return dataStocks.map(x => x.coupon_percu_souscription_encours_customer).reduce(reducer);
        }
    }

    // Somme Résultat net depuis la souscription
    const netResultSinceSubscription = () => {
        if (dataStocks && dataStocks.length > 0) {
            const reducer = (accumulator, currentValue) => accumulator + currentValue;
            return dataStocks.map(x => x.resultat_net_souscription_encours_customer).reduce(reducer);
        }
    }


    // Encart Total
    const titles_total = [
        "Montant :",
        "Montant valorisé en J :",
        "Montant garanti :",
        "+/- Value latente depuis la souscription :",
        "Coupons perçus depuis la souscription :",
        "Résultat net depuis la souscription :"
    ];

    const rows_total = [
        price(amount()),
        price(amountValuedInDays()),
        price(guaranteedAmount()),
        price(unrealizedValueSinceSubscription()),
        price(couponsReceivedSinceSubscription()),
        price(netResultSinceSubscription())
    ];


    // Encart Détails
    const titles = ["Instrument financier", "Référence", "Émetteur", "Date de début", "Montant", "+/- Value latente depuis la souscription", "Date d'échéance", "Rendement des cp", "Montants valorisés en J", ""];

    // Encart du déroulé d'un instrument financier
    const titles_details = [
        "Nombre de parts : ",
        "Catégorie de produits : ",
        "Rendement net : ",
        "Indexation :",
        "Dernière valorisation :",
        "Coupons perçus depuis la souscription :",
        "Espérance de rendement :",
        "",
        "Date dernière valorisation :",
        "Résultat net depuis la souscription :",
        "Montant garanti :",
        "",
        "Commentaire :",
        "",
        "",
        "",
        "Actualité :",
        "",
        ""
    ];

    const titles_details_bank = [
        "Rendement net : ",
        "Coupons perçus depuis la souscription :",
        "Espérance de rendement :",
        "Commentaire :",
        "Résultat net depuis la souscription :",
        "Montant garanti :",
        "Actualité :",
        "",
        "",
        "",
        ""
    ];

    // On trie le tableau suivant la date d'échéance de la plus proche à la plus lointaine
    function compare_expiryDate(a, b) {
        if (a.date_fin < b.date_fin) {
            return -1;
        } else if (a.date_fin > b.date_fin) {
            return 1;
        } else {
            return 0;
        }
    }

    return (
        <>
            <Snackbar open={displaySnackbar} onClose={() => setDisplaySnackbar(false)} autoHideDuration={2000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Paper style={{ padding: 10, backgroundColor: 'red' }}>
                    <Typography style={{ color: 'white' }}>Pas de fichier PDF disponible</Typography>
                </Paper>
            </Snackbar>

            <Header title={assetClass} />

            <Typography variant="h4" className={classes.date}>
                {encoursDate}
                {/* {formatDate(new Date())} */}
            </Typography>

            <Grid className={classes.actions} container spacing={0}>
                <Grid item xs={12} sm={10} md={10}>
                    <Box className={classes.whiteContainer}>
                        <Icon className={`fa fa-${fetching.every(elt => elt) ? `sync ${classes.rotate}` : "check-circle"} ${classes.icon}`} />
                        <Typography variant="h2">
                            <Box className={classes.titleTotal}>Total</Box>
                        </Typography>
                        {dataStocks && dataStocks.length > 0 ?
                            <Grid container spacing={3}>
                                {titles_total.map((title, index) => {
                                    const value = rows_total[index];
                                    return (<Grid key={index} item xs={6} sm={4}>
                                        <Box className={classes.categories}>
                                            <Typography className={classes.subtitleDetails} variant="h4">{title}</Typography>
                                            <Typography variant="h3">{value}</Typography>
                                        </Box>
                                    </Grid>)
                                })}
                            </Grid> :
                            <Box className={classes.tableContainer}>
                                <CircularProgress />
                            </Box>
                        }

                        <Typography variant="h2">
                            <Box className={classes.titleDetails}>Détail</Box>
                        </Typography>
                      
                        {/* Spécifique à la classe d'actif "Ep Bancaire" */}
                        {assetClass === 'Ep Bancaire' ?
                            dataStocks && dataStocks.length > 0 ?                            
                                <TableA7Stocks
                                    titles={titles} rows={dataStocks.sort(compare_expiryDate).map(line => [
                                        // Reference
                                        line.instrument_id[1],
                                        store.instrumentArray && store.instrumentArray.length > 0 && store.instrumentArray[line.instrument_id[0]] ? 
                                        store.instrumentArray[line.instrument_id[0]].code_isin : '',
                                        store.instrumentArray && store.instrumentArray.length > line.instrument_id[0] ? store.instrumentArray[line.instrument_id[0]].emetteur_id[1] : '',
                                        new Date(line.date_debut).toLocaleDateString(undefined, options),
                                        price(line.montant_reel_encours_customer.toFixed(2)),
                                        price(line.plus_value_souscription_encours_customer),
                                        line.date_fin ? new Date(line.date_fin).toLocaleDateString(undefined, options) : '',
                                        line.rendement_coupon_couru_encours_customer.toFixed(2) + '%',
                                        price(line.montant_valorisation_encours_customer.toFixed(2)),
                                    ])}

                                    titles_details={titles_details_bank} rows_details={dataStocks.map((line, i) => [
                                        line.rendement_net_souscription_encours_customer.toFixed(2) + '%',
                                        line.categorie_produit[1],
                                        line.rendement_estime,
                                        <TextareaAutosize aria-label="minimum height" rowsMin={5}
                                            value={store.instrumentArray && store.instrumentArray.length > 0 ? 
                                                store.instrumentArray[line.instrument_id[0]].commentaire : ''}
                                            style={{ borderRadius: 10, border: 'none', width: "35vh" }}
                                        />,
                                        price(line.resultat_net_souscription_encours_customer),
                                        price(line.montant_garanti_encours_customer),
                                        <TextareaAutosize aria-label="minimum height" rowsMin={4}
                                            value={store.commentArray && store.commentArray.length > 0 && store.instrumentArray && store.instrumentArray.length > 0 ?
                                                typeof store.commentArray.find(x => x.id === (store.instrumentArray[line.instrument_id[0]].comments.slice(-1)[0])) === 'undefined' ?
                                                    '' : store.commentArray.find(x => x.id === (store.instrumentArray[line.instrument_id[0]].comments.slice(-1)[0])).text
                                                : ''}
                                            style={{ borderRadius: 10, border: 'none', width: "30vh" }}
                                        />,
                                        <Button className={classes.buttonPdf} onClick={() => {
                                            setLoading(true);
                                            // Eviter le spin à l'infini 
                                            const id = setTimeout(() => { setLoading(false); setDisplaySnackbar(true) }, 2000)
                                            Lil.method("auth/readPdfFinancialInstrument", { instrument_id: line.instrument_id[0] }, 
                                            result => { Lil.DownloadPdf(result[0], "datas", result[0].datas_fname); setLoading(false); clearTimeout(id) })
                                        }}>
                                            {loading ? <CircularProgress /> : <>Télécharger le PDF &nbsp; <i className="fas fa-file-download" /> </>}
                                        </Button>,
                                    ])}
                                />

                                :

                                <Box className={classes.tableContainer}>
                                    <CircularProgress />
                                </Box>

                            :

                            dataStocks && dataStocks.length > 0 ?
                                <TableA7Stocks
                                    titles={titles} rows={dataStocks.map(line => [
                                        // Reference
                                        line.instrument_id[1],
                                        store.instrumentArray && 
                                        store.instrumentArray.length > 0 &&
                                        store.instrumentArray[line.instrument_id[0]] &&
                                        store.instrumentArray[line.instrument_id[0]].code_isin ?
                                        (store.instrumentArray[line.instrument_id[0]].code_isin) : (''),
                                        store.instrumentArray && store.instrumentArray.length > 0 ? store.instrumentArray[line.instrument_id[0]].emetteur_id[1] : '',
                                        new Date(line.date_debut).toLocaleDateString(undefined, options),
                                        price(line.montant_reel_encours_customer.toFixed(2)),
                                        price(line.plus_value_souscription_encours_customer),
                                        line.date_fin ? new Date(line.date_fin).toLocaleDateString(undefined, options) : '',
                                        line.rendement_coupon_couru_encours_customer.toFixed(2) + '%',
                                        price(line.montant_valorisation_encours_customer.toFixed(2)),
                                    ])}

                                    titles_details={titles_details} rows_details={dataStocks.map((line, i) => [
                                        line.nombre,
                                        line.categorie_produit[1],
                                        line.rendement_net_souscription_encours_customer.toFixed(2) + '%',
                                        store.indexationArray ? line.indexation_ids.map(x => store.indexationArray[x]).join(' - ') : <CircularProgress />,
                                        price(line.derniere_valorisation_encours_customer),
                                        price(line.coupon_percu_souscription_encours_customer),
                                        line.rendement_estime,
                                        <Button className={classes.buttonPdf} onClick={() => {
                                            setLoading(true);
                                            // Eviter le spin à l'infini 
                                            const id = setTimeout(() => { setLoading(false); setDisplaySnackbar(true) }, 2000)
                                            Lil.method("auth/readPdfFinancialInstrument", { instrument_id: line.instrument_id[0] }, result => 
                                            { Lil.DownloadPdf(result[0], "datas", result[0].datas_fname); setLoading(false); clearTimeout(id) })
                                        }}>
                                            {loading ? <CircularProgress /> : <>Télécharger le PDF &nbsp; <i className="fas fa-file-download" /> </>}
                                        </Button>,
                                        new Date(line.date_derniere_valorisation).toLocaleDateString(undefined, options),
                                        price(line.resultat_net_souscription_encours_customer),
                                        price(line.montant_garanti_encours_customer),
                                        <Button onClick={() => { router.push(`/app/stocksdetails/${selectedClientId}/${assetClass}/${i}/${line.categorie_produit[1]}`) }}
                                            className={classes.buttonGraphic} >Graphique de valorisation
                                        </Button>,
                                        <TextareaAutosize aria-label="minimum height" rowsMin={4}
                                            value={store.instrumentArray && store.instrumentArray.length > 0 ? store.instrumentArray[line.instrument_id[0]].commentaire : ''}
                                            style={{ borderRadius: 10, border: 'none', width: "90vh" }}
                                        />,
                                        "",
                                        "",
                                        "",
                                        <TextareaAutosize aria-label="minimum height" rowsMin={4}
                                            value={store.commentArray && store.commentArray.length > 0 && store.instrumentArray && store.instrumentArray.length > 0 ?
                                                typeof store.commentArray.find(x => x.id === (store.instrumentArray[line.instrument_id[0]].comments.map(x => x).slice(-1)[0])) === 'undefined' ?
                                                    '' : store.commentArray.find(x => x.id === (store.instrumentArray[line.instrument_id[0]].comments.map(x => x).slice(-1)[0])).text
                                                : ''}
                                            style={{ borderRadius: 10, border: 'none', width: "90vh" }}
                                        />,
                                    ])}
                                />

                                :

                                <Box className={classes.tableContainer}>
                                    <CircularProgress />
                                </Box>
                        }
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

const useStyles = makeStyles(theme => {
    return ({
        actions: {
            backgroundColor: theme.palette.light.main,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        date: {
            textAlign: 'center'
        },
        whiteContainer: {
            position: "relative",
            padding: theme.spacing(10),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2),
            },
            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(6),
            },
            marginTop: 74,
            marginBottom: 80,
            backgroundColor: theme.palette.white.main,
            boxShadow: `0px 3px 15px ${makeTransparent(theme.palette.primary.main, 0.5)}`,
            borderRadius: 40,
        },
        titleTotal: {
            textAlign: "left",
            marginBottom: 35
        },
        categories: {
            maxWidth: 215
        },
        titleDetails: {
            textAlign: "left",
            marginBottom: 35,
            marginTop: 65
        },
        buttonDetails: {
            width: 100,
            height: 28,
            fontSize: 14,
            padding: 0,
            borderRadius: 10,
            backgroundColor: 'transparent',
            color: theme.palette.primary.main
        },
        buttonReduce: {
            width: 100,
            height: 28,
            fontSize: 14,
            padding: 0,
            marginBottom: 10,
            borderRadius: 10,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.white.main
        },
        subtitleDetails: {
            fontWeight: 'bold',
            color: theme.palette.primary.main
        },
        buttonPdf: {
            width: 272,
            height: 50,
            backgroundColor: theme.palette.white.main,
            borderColor: theme.palette.white.main,
            color: theme.palette.primary.main
        },
        buttonGraphic: {
            width: 272,
            height: 50
        },
        rotate: {
            WebkitAnimation: "spin 2s linear infinite;",
            MozAnimation: "spin 2s linear infinite;",
            animation: "spin 2s linear infinite;",
        },
        icon: {
            color: theme.palette.primary.main,
            position: "absolute",
            top: "15px",
            right: "60px",
        },
    });
});