import React from 'react';
import { makeStyles, Grid, Box, Typography } from '@material-ui/core';

export default function NotFound() {

  const classes = useStyles();

  return (
    <Box className={classes.Container}>
      <Typography>
        <Grid className={classes.Error}>404</Grid>
        <Grid className={classes.Error}>Not Found</Grid>
      </Typography>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  Container: {
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    margin: 100
  },
  Error: {
    color: theme.palette.primary.main,
    fontSize: 80,
    textAlign: 'center'
  },
}))
