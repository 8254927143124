import { useTheme } from '@material-ui/core';
import React from 'react';
import {
  AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid
} from 'recharts';
import CustomTooltip from './CustomTooltip';

const extracter = /^(?<day>[0-9]{2})\/(?<month>[0-9]{2})\/(?<year>[0-9]{4})$/;

const getDateFromString = (dateString) => {
  const match = dateString.match(extracter);
  if (!match) return null;
  const { day, month, year } = match.groups;
  return new Date(year, month - 1, day);
}

export default function SimpleAreaChartA7({ width, data }) {
  // Séparateur des milliers => props tickFormatter à Yaxis
  const formatter = (value) => new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(value);
  const theme = useTheme();
  
  const dataMin = Math.min(...data.map(e => e.valeur));
  const dataMax = Math.max(...data.map(e => e.valeur));
  const diff = dataMax - dataMin;

  return (
    <AreaChart margin={{ top: 20, right: 20, left: 65 }} width={width} height={400} data={data.map(e => ({ ...e, dataDate: getDateFromString(e.dataDate).getTime()}))}>
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="20%" stopColor={theme.palette.primary.main} stopOpacity={0.8} />
          <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
        </linearGradient>
      </defs>

      <XAxis
        scale="time"
        type="number"
        padding={{ left: 30, right: 30 }}
        stroke={theme.palette.primary.main}
        tickLine={true}
        axisLine={true}
        dataKey="dataDate"
        tickFormatter={(label) => new Date(label).toLocaleDateString()}
        domain={['auto', 'auto']}
      />
      <YAxis
        stroke={theme.palette.primary.main}
        tickLine={true}
        axisLine={true}
        type="number"
        tickFormatter={formatter}
        domain={[Math.ceil(dataMin - 0.1*diff), Math.floor(dataMax + 0.1*diff)]}
      />
      <Tooltip content={<CustomTooltip />} />

      <Area type="linear" dataKey="valeur" stroke={theme.palette.primary.main} fillOpacity={1} fill="url(#colorUv)" strokeWidth="4" />

      <CartesianGrid strokeDasharray="3 3" />

    </AreaChart>
  );
}