import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import Lil from '@loginline/core';

// Components
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';

//Pages
import Connexion from './Pages/Connexion';
import Clients from './Pages/Clients';
import Home from './Pages/Home';
import Stocks from './Pages/Stocks';
import StocksDetails from './Pages/StocksDetails';
import Notifications from './Pages/Notifications';
import Events from './Pages/Events';
import Synthesis from './Pages/Synthesis';
import MyAccount from './Pages/MyAccount';
import NotFound from './Pages/NotFound';
import Demo from './Pages/Demo';

function App() {

  const [store, update] = Lil.useStore();

  useEffect(() => {
    update && Lil.method('pub/logoUrl', {}, result => {
      update({
        logo: result.url
      });
    });
    update && Lil.method('auth/companyLinks', {}, result => {
      update({
        links: {
          legalMentions: result.legalMentions,
          contact: result.contact,
        }
      });
    });
  }, [update])

  useEffect(() => {
    if(Lil.Account.user && store.userLoggedIn){
  Lil.method('auth/readClients', {
    fields: ['id', 'name'],
  }, result => {
    update({ names: [...result.map(client => ({ id: client.id, name: client.name }))] });
  })
    }
    if(Lil.Account.user && !store.userLoggedIn){
      update({userLoggedIn: true})
    }
  }, [store.userLoggedIn, update]);

  // eslint-disable-next-line

  const router = useHistory();

  return (
    <div className="App">
      <Route path='/app/:currentPage/:selectedClientId'
        render={() => <Navbar clients={store.names} logoutFunction={() => { Lil.Account.logout();update({userLoggedIn: false}); router.push("/") }} />} />
      <Switch>
        <Route exact path='/' component={Connexion} />
        <Route path='/clients' component={Clients} />
        <Route path='/demo' component={Demo} />
        <Route path='/app/home/:selectedClientId' component={Home} />
        <Route path='/app/stocks/:selectedClientId/:assetClass' component={Stocks} />
        <Route path='/app/stocksdetails/:selectedClientId/:assetClass/:selectedAssetClassId/:productClass' component={StocksDetails} />
        <Route path='/app/notifications/:selectedClientId' component={Notifications} />
        <Route path='/app/events/:selectedClientId' component={Events} />
        <Route path='/app/synthesis/:selectedClientId' component={Synthesis} />
        <Route path='/app/myaccount' component={MyAccount} />
        <Route path='/' component={NotFound} />
      </Switch>
      <Route path='/app' component={Footer} />
    </div>
  );
}

export default hot(module)(App);
