import React, { useEffect, useReducer } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Lil from '@loginline/core';
import { BrowserRouter as Router } from "react-router-dom";
import buildTheme from './Styles/theme';
import './Styles/index.css';
import App from './App';
import Introspecter from "./framework/Introspecter/Introspecter";
import ScrollToTop from './Components/ScrollToTop';

const initialState = {
    names: [],
};

const LilReact = () => {
    const [store, dispatch] = useReducer(Lil.LilReducer, initialState, Lil.LilInitState);
    const [theme, setTheme] = React.useState(createMuiTheme(buildTheme({ primary: '#888' })));

    useEffect(() => {
        Lil.method('pub/getColors', {}, result => {
            setTheme(createMuiTheme(buildTheme({
                primary: result.primary,
                customColors: result.other,
            })))
        });
    }, []);

    return (
        <Lil.LilContext.Provider value={[store, dispatch]}>
            <ThemeProvider theme={theme}>
                <Router>
                    <ScrollToTop>
                        <App />
                        {process.env.NODE_ENV === "development" && <Introspecter />}
                    </ScrollToTop>
                </Router>
            </ThemeProvider>
        </Lil.LilContext.Provider>
    )
};

ReactDOM.render(<LilReact />, document.getElementById('root'));
