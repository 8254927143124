import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography, CircularProgress } from '@material-ui/core';
import { useParams } from "react-router-dom";
import Header from '../Components/Header';
import Lil from '@loginline/core';
import makeTransparent from '../utils/makeTransparent';

export default function Notifications() {
    const classes = useStyles();
    const [notifs, setNotifs] = useState([]);
    const [loading, setLoading] = useState(true);
    const title = "Notifications";
    let { selectedClientId } = useParams();

    // Cycle de vie
    useEffect(() => {
        Lil.method('auth/readNotif', { id: selectedClientId },
            result => { setNotifs(result); setLoading(false) })
            .catch(err => {  setLoading(false); })
    }, [selectedClientId]);

    const removeNotif = (indexBack, indexFront) => {
        Lil.method('auth/deleteNotif', { index: indexBack }, () => setNotifs(notifs.filter(notif => notif.id !== indexBack)));
    }

    function formatDate(input) {
        var datePart = input.match(/\d+/g),
            year = datePart[0].substring(2), // get only two digits
            month = datePart[1], day = datePart[2];
        return day + '/' + month + '/' + year;
    }

    return (
        <>
            <Header title={title} />
            
            <Grid className={classes.notifications} container spacing={0}>
                <Grid item xs={12} sm={8} md={6}>
                    
                        <>
                            {loading ?
                                <CircularProgress className={classes.loading} />
                                :
                                notifs.length > 0 ?
                                    notifs.map((notif, indexFront) => {
                                        return (
                                            <Box key={indexFront} className={classes.notificationContainer}>
                                                <Grid item xs={3} md={3}>
                                                    <Typography variant="h4" className={classes.notificationDate}>
                                                        {formatDate(notif.date)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8} md={8}>
                                                    <Typography variant="body1" className={classes.notificationText}>
                                                        {notif.commentary}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1} md={1} className={classes.colorIcon}>
                                                    <i className="fas fa-trash fa-lg" style={{ cursor: 'pointer' }}
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            removeNotif(notif.id, indexFront);
                                                        }}
                                                    ></i>
                                                </Grid>
                                            </Box>
                                        )
                                    })
                                    :
                                    <Box className={classes.tableContainer}>
                                        <Typography variant='h2'>Aucune notification à afficher</Typography>
                                    </Box>
                            }
                        </>
                    
                </Grid>
            </Grid>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    notifications: {
        backgroundColor: theme.palette.light.primary,
        display: "flex",
        justifyContent: "center",
        marginTop: 75,
        minHeight: '75vh'
    },
    notificationList: {
        display: "flex",
        flexDirection: "column",
        textAlign: 'center',
        justifyContent: 'center'
    },
    notificationContainer: {
        background: `linear-gradient(to right, ${theme.palette.primary.main} 22%, white 11.5%)`,
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 40,
        paddingLeft: "3%",
        paddingRight: "3%",
        paddingTop: "3%",
        paddingBottom: "3%",
        marginBottom: 30,
        textAlign: 'center',
    },
    notificationDate: {
        color: theme.palette.white.main,
        textAlign: 'center',
        fontSize: "1em",
        paddingRight: '20%',
    },
    colorIcon: {
        color: theme.palette.primary.main,
        textAlign: 'center'
    },
    loading: {
        padding: 100,
        marginLeft: 250
    },
    tableContainer: {
        position: "relative",
        padding: theme.spacing(2),
        textAlign: 'center',
        marginTop: 30,
        marginBottom: 80,
        backgroundColor: theme.palette.white.main,
        boxShadow: `0px 3px 15px ${makeTransparent(theme.palette.primary.main, 0.5)}`,
        borderRadius: 40,
        alignItems: "center",
        paddingLeft: 80,
        paddingRight: 80,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 10,
            paddingRight: 10
        },
    },
}));
