const hexaColor = /^#([0-9A-F]{1,2}){3,4}$/i;
const rgbColor = /^rgb[a]?\((\d+),\s*(\d+),\s*(\d+)(,\s*(\d+))?\)$/;

export default (color, opacity) => {
  if (hexaColor.test(color)) {
    const hexa = color.replace('#', '');
    const r = parseInt(hexa.substring(0, 2), 16);
    const g = parseInt(hexa.substring(2, 4), 16);
    const b = parseInt(hexa.substring(4, 6), 16);
    return `rgba(${r},${g},${b},${opacity})`;
  } else if (rgbColor.test(color)) {
    const [, r, g, b] = color.match(rgbColor);
    return `rgba(${r},${g},${b},${opacity})`;
  } else {
    return color;
  }
};