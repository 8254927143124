import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Box,
  Typography,
  CircularProgress,
  Icon,
  Table,
  TableRow,
  TableCell,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useParams, useHistory } from "react-router-dom";
import Lil from "@loginline/core";
import TableA7 from "../Components/TableA7";
import PieChartA7 from "../Components/PieChart";
import makeTransparent from "../utils/makeTransparent";

const DOCUMENTS_PAGE_SIZE = 10;

export default function Home() {
  const [clientName, setClientName] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingDocs, setLoadingDocs] = useState(false);
  const [store, update] = Lil.useStore();
  let { selectedClientId } = useParams();
  const classes = useStyles();
  const router = useHistory();
  const [documentPage, setDocumentPage] = useState(1);
  const [documentType, setDocumentType] = useState("all");
  const [nDocs, setNDocs] = useState(0);
  const [forceRefresh, setForceRefresh] = useState(false);

  useEffect(() => {
    if (selectedClientId) {
      setLoading(true);
      const fetch = async () => {
        await Lil.method("auth/getClient", {
          id: selectedClientId,
          fields: ['name', 'date_analyse_encours_customer'],
        }, result => {
          setClientName(result[0].name);
          update({ client: result });
        });
        await Lil.method(
          "auth/readClientsHome",
          {
            id: selectedClientId,
            fields: ['name', 'montant_investi', 'montant_valorisation', 'plus_value_souscription'],
          },
          result => {
            update({ datasHome: result });
          }
        );
        setLoading(false);
        await Lil.method("auth/readNotif", { id: selectedClientId }, result => {
          update({ notifications: result });
        });
        update({ datasStocks: [] });
        update({ instrumentArray: [] });

      };

      fetch();
    }
  }, [selectedClientId, update]);

  useEffect(() => {
    setNDocs(0);
    setDocumentPage(1);
    setForceRefresh(!forceRefresh);
  }, [documentType, selectedClientId]);

  useEffect(() => {
    setLoadingDocs(true);
    Lil.method(
      "auth/readDocuments",
      {
        client_id: selectedClientId,
        fields: ["id", "name", "create_date"],
        offset: (documentPage - 1) * DOCUMENTS_PAGE_SIZE,
        limit: DOCUMENTS_PAGE_SIZE,
        order: "create_date desc",
        documentType,
      },
      result => {
        update({ documents: result.docs });
        setNDocs(result.totalCount);
        setLoadingDocs(false);
      }
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentPage, forceRefresh]);

  // Date reporting
  const encoursDateBack = store.client
    ? store.client[0].date_analyse_encours_customer
    : " ";
  const encoursDate =
    encoursDateBack !== false
      ? "reporting au " + formatDate(encoursDateBack)
      : " ";

  function formatDate(input) {
    const datePart = input.match(/\d+/g);
    if (datePart && datePart.length) {
      const year = datePart[0];
      const month = datePart[1];
      const day = datePart[2];
      return day + "/" + month + "/" + year;
    } else {
      return "...";
    }
  }

  // Mise en forme des données du tableau
  const price = number =>
    new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "EUR",
    }).format(number);

  // Titres du tableau
  const titles = [
    "Classe d'actifs",
    "Investissement",
    "Valorisation",
    "Écart",
    "",
  ];

  const downloadDocument = id => {
    Lil.method(
      "auth/readDocument",
      {
        id,
        fields: ["datas_pdf", "datas_fname"],
      },
      result => {
        Lil.DownloadPdf(result[0], "datas_pdf", result[0].datas_fname);
      }
    );
  };

  // On trie le tableau suivant le montant investi et on place toujours Liquidité en dernier
  function compare_investment(a, b) {
    if (a.display_name === "Liquidité") {
      return 1;
    }
    if (b.display_name === "Liquidité") {
      return -1;
    }
    if (a.montant_investi > b.montant_investi) {
      return -1;
    } else if (a.montant_investi < b.montant_investi) {
      return 1;
    } else {
      return 0;
    }
  }

  return (
    <>
      <Typography variant='h1' className={classes.title}>
        {clientName}
      </Typography>

      <Typography variant='h4' className={classes.date}>
        {encoursDate}
        {/* {formatDate(new Date())} */}
      </Typography>

      <Box className={classes.home}>
        <Box className={classes.firstPicture}></Box>
        <Grid className={classes.table}>
          <Grid item xs={12} sm={12} md={8}>
            {store.datasHome && store.datasHome.length > 0 ? (
              <Box className={classes.tableContainer}>
                <Icon
                  className={`fa fa-${
                    loading ? `sync ${classes.rotate}` : "check-circle"
                  } ${classes.icon}`}
                />
                <TableA7
                  titles={titles}
                  rows={store.datasHome
                    .sort(compare_investment)
                    .map((line, i) => [
                      line.name,
                      price(line.montant_investi.toFixed(2)),
                      price(line.montant_valorisation.toFixed(2)),
                      price(line.plus_value_souscription.toFixed(2)),
                      <Button
                        className={classes.buttonSee}
                        onClick={() => {
                          router.push(
                            `/app/stocks/${selectedClientId}/${store.datasHome[i].name}`
                          );
                        }}
                      >
                        Voir
                      </Button>,
                    ])}
                  total={[
                    <Button
                      className={classes.buttonTotal}
                      style={{ pointerEvents: "none" }}
                    >
                      Total
                    </Button>,
                    <Typography
                      variant='body1'
                      className={classes.totalCategory}
                    >
                      {price(
                        store.datasHome
                          .flat()
                          .map(x => x.montant_investi)
                          .reduce(
                            (accumulator, currentValue) =>
                              accumulator + currentValue
                          )
                      )}
                    </Typography>,
                    <Typography
                      variant='body1'
                      className={classes.totalCategory}
                    >
                      {price(
                        store.datasHome
                          .flat()
                          .map(x => x.montant_valorisation)
                          .reduce(
                            (accumulator, currentValue) =>
                              accumulator + currentValue
                          )
                      )}
                    </Typography>,
                    <Typography
                      variant='body1'
                      className={classes.totalCategory}
                    >
                      {price(
                        store.datasHome
                          .flat()
                          .map(x => x.plus_value_souscription)
                          .reduce(
                            (accumulator, currentValue) =>
                              accumulator + currentValue
                          )
                      )}
                    </Typography>,
                    "",
                  ]}
                />
              </Box>
            ) : loading ? (
              <Box className={classes.tableContainer}>
                <CircularProgress />
              </Box>
            ) : (
              <Box className={classes.tableContainer}>
                <Typography variant='h3'>
                  Aucune données à afficher pour ce client
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>

        <Grid className={classes.containerSubtitle} item xs={8} md={12}>
          <Typography variant='h3' className={classes.subtitle}>
            Ventilation par classe d'actifs
          </Typography>
        </Grid>

        {store.datasHome && (
          <PieChartA7
            name={store.datasHome.map(data => data.name)}
            valo={store.datasHome.map(data => data.montant_valorisation)}
          />
        )}

        <Grid className={classes.download}>
          <Box className={classes.secondPicture}></Box>
          <Grid className={classes.whiteContainer} item xs={12} sm={8} md={6}>
            <Box>
              <Typography className={classes.titleWhiteContainer} variant='h3'>
                Documents
              </Typography>
            </Box>
            <Box style={{ marginBottom: 20 }}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel
                  id="document-type-label"
                  style={{ background: 'white', margin: '0 3px' }}
                >
                  Filtrer par type
                </InputLabel>
                <Select
                  label="Age"
                  labelId="document-type-label"
                  value={documentType}
                  onChange={e => setDocumentType(e.target.value)}
                >
                  <MenuItem value="all">
                    Tous les documents
                  </MenuItem>
                  <MenuItem value="encours">
                    Rapports des encours
                  </MenuItem>
                  <MenuItem value="encours_repart">
                    Répartitions des encours
                  </MenuItem>
                  <MenuItem value="encours_synth">
                    Rapports synthétique des encours
                  </MenuItem>
                  <MenuItem value="analyse_periode">
                    Résultats période
                  </MenuItem>
                  <MenuItem value="operations">
                    Listes des opérations
                  </MenuItem>
                  <MenuItem value="encours_repart_emetteur">
                    Répartitions des encours par émetteur
                  </MenuItem>
                  <MenuItem value="other">
                    Autres documents
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            {loadingDocs ? (
              <CircularProgress />
            ) : (
              <Table>
                {store.documents &&
                  store.documents.map((document, i) => (
                    <TableRow>
                      {/* <TableCell style={{ padding: 0 }}>{document.create_date.slice(0, 10)}</TableCell> */}
                      <TableCell style={{ padding: 0 }}>
                        {document.name}
                      </TableCell>
                      <TableCell style={{ padding: 0, textAlign: 'right' }}>
                        <IconButton
                          onClick={() => downloadDocument(document.id)}
                        >
                          <SaveIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                {store.documents && store.documents.length === 0 && (
                  <TableRow>
                    <TableCell style={{ padding: 0 }}>
                      Aucun document à afficher
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            )}
            <Box className={classes.buttonWhiteContainer}>
              {nDocs > DOCUMENTS_PAGE_SIZE && (
                Array.from({ length: Math.ceil(nDocs / DOCUMENTS_PAGE_SIZE) }).map(
                  (_, i) => (
                    <Button
                      onClick={() => setDocumentPage(i + 1)}
                      style={{
                        margin: 5,
                        width: 'unset',
                        background: i + 1 !== documentPage && 'white',
                        color: i + 1 !== documentPage && 'black'}}
                    >
                      {i + 1}
                    </Button>
                  )
                ) 
              )}
            </Box>
          </Grid>
          <Box className={classes.thirdPicture}></Box>
        </Grid>
      </Box>
    </>
  );
}

const useStyles = makeStyles(theme => {
  return {
    home: {
      backgroundColor: theme.palette.light.main,
    },
    title: {
      marginTop: 30,
      [theme.breakpoints.down("sm")]: {
        fontSize: 40,
        marginTop: 0,
      },
    },
    table: {
      display: "flex",
      justifyContent: "center",
    },
    tableContainer: {
      position: "relative",
      padding: theme.spacing(6),
      textAlign: "center",
      marginTop: 70,
      marginBottom: 40,
      backgroundColor: theme.palette.white.main,
      boxShadow: `0px 3px 15px ${makeTransparent(
        theme.palette.primary.main,
        0.5
      )}`,
      borderRadius: 40,
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    buttonTotal: {
      maxWidth: 160,
      marginLeft: "3%",
      borderRadius: "10px 0px 0px 10px",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white.main,
      },
    },
    totalCategory: {
      fontWeight: "bold",
      marginTop: 5,
    },
    buttonSee: {
      width: 80,
      height: 28,
      padding: 0,
      fontSize: 14,
      borderRadius: 10,
    },
    containerSubtitle: {
      position: "relative",
      width: 580,
      height: 80,
      marginTop: 25,
      backgroundColor: theme.palette.primary.main,
      borderRadius: "0px 20px 20px 0px",
      textAlign: "center",
      color: theme.palette.white.main,
    },
    subtitle: {
      paddingTop: 23,
      [theme.breakpoints.down("xs")]: {
        fontSize: 21,
      },
    },
    download: {
      position: "relative",
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
    whiteContainer: {
      position: "relative",
      backgroundColor: theme.palette.white.main,
      boxShadow: `0px 3px 15px ${makeTransparent(
        theme.palette.primary.main,
        0.5
      )}`,
      alignItems: "center",
      textAlign: "center",
      marginTop: 30,
      marginBottom: 150,
      marginRight: 115,
      borderRadius: 20,
      padding: "80px 100px 80px 100px",
      [theme.breakpoints.down("sm")]: {
        marginRight: 0,
      },
      [theme.breakpoints.down("xs")]: {
        marginRight: 50,
        marginLeft: 50,
        padding: "40px 50px 40px 50px",
      },
    },
    titleWhiteContainer: {
      marginBottom: 32,
    },
    buttonWhiteContainer: {
      marginTop: 32,
    },
    firstPicture: {
      position: "absolute",
      width: "50%",
      height: 475,
      backgroundImage: "url('../../home-picture.jpg')",
      borderRadius: "0px 0px 40px 0px",
      zIndex: 0,
      left: 0,
      top: 480,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    secondPicture: {
      position: "absolute",
      width: "25%",
      height: 680,
      backgroundImage: "url('../../home-second-pic.jpg')",
      borderRadius: "40px 0px 0px 0px",
      zIndex: 0,
      right: 0,
      bottom: 230,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    thirdPicture: {
      position: "absolute",
      width: "50%",
      height: 500,
      backgroundImage: "url('../../home-picture.jpg')",
      borderRadius: "00px 40px 0px 0px",
      zIndex: -1,
      left: 0,
      bottom: 0,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    date: {
      marginTop: 20,
      textAlign: "center",
    },
    rotate: {
      WebkitAnimation: "spin 2s linear infinite;",
      MozAnimation: "spin 2s linear infinite;",
      animation: "spin 2s linear infinite;",
    },
    icon: {
      color: theme.palette.primary.main,
      position: "absolute",
      top: "15px",
      right: "60px",
    },
  };
});
