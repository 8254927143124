import React, { useState } from 'react';
import {
  LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid
} from 'recharts';
import { Grid, makeStyles, useTheme } from '@material-ui/core';
import Button from "@material-ui/core/Button";

export default function SimpleLineChartA7({ width, data, assetClasses }) {
  const theme = useTheme();
  const colors = theme.palette.customColors || [];
  const [toggle, setToggle] = useState([]);
  const [toggleTotal, setToggleTotal] = useState(true);
  const classes = useStyles();

  // Séparateur des milliers => props tickFormatter à Yaxis
  const price = (number) => new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(number);
  const formatter = (value) => price(value);

  return (
    <Grid>
      <Grid>
        <LineChart margin={{ top: 20, right: 20, left: 40 }} width={width} height={500} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis stroke={theme.palette.primary.main} tickLine={true} axisLine={true} dataKey="formatUpdate" padding={{ left: 30, right: 30 }} />
          <YAxis stroke={theme.palette.primary.main} tickLine={true} axisLine={true} tickFormatter={formatter} />
          <Tooltip formatter={(value) => new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(value)} />

          {assetClasses.map((assetClass, index) => !toggle.includes(assetClass) ?
            <Line key={index} type="linear" dataKey={assetClass} stroke={colors[index]}
              strokeWidth="4" activeDot={{ r: 0 }} dot={false} />
            :
            ""
          )}
          {
            toggleTotal &&
            <Line type="linear" dataKey="Total" stroke="#21629f" strokeWidth="4" activeDot={{ r: 0 }} dot={false} />
          }

        </LineChart>
      </Grid>
      <Grid className={classes.wrapBtns}>

      <Button className={classes.whiteBtn}
          style={toggleTotal ? { color: "black", backgroundColor: "white" } : { color: "grey", backgroundColor: "white" }}
          onClick={() => {
            setToggleTotal(!toggleTotal); 
            toggle.length === 0 ? setToggle(assetClasses) : setToggle([])
          }}>{"Tout"}</Button>

        {assetClasses.map((assetClass, index) => (
          <Button key={index} className={classes.whiteBtn}
            style={toggle.includes(assetClass) ?
              { color: "grey", backgroundColor: "white" } : { color: "white", backgroundColor: colors[index] }}
            onClick={() => {
              toggle.includes(assetClass) ? setToggle(toggle.filter(togasset => togasset !== assetClass))
                : setToggle(toggle.concat(assetClass))
            }}>{assetClass}</Button>
        ))}

        <Button className={classes.whiteBtn}
          style={toggleTotal ? { color: "white", backgroundColor: "#21629f" } : { color: "grey", backgroundColor: "white" }}
          onClick={() => { setToggleTotal(!toggleTotal) }}>{"Total"}</Button>
      </Grid>
    </Grid>

  );
}

const useStyles = makeStyles(theme => ({
  whiteBtn: {
    border: 'none',
    margin: "40px 40px 0 0",
    padding: 10,
    width: "auto",
    height: 28,
    [theme.breakpoints.down('sm')]: {
      margin: 10,
    },
  },
  actionsBtnWrapper: {
    textAlign: "center",
    justifyContent: "center"
  },
  wrapBtns: {
    textAlign: 'center'
  }
}))