import React, {useContext}  from "react";

const LilContext = React.createContext();

const Lil = global.Lil = {
    Account: {},
    LilContext,
    Store: {},
    LilReducer: (state, merged) => {
        Lil.Store === "reset" ? Lil.Store = {...Lil.InitialStore} : Lil.Store = {...state, ...merged};
        return Lil.Store;
    },
    LilInitState: (state) => {
        if(!Lil.InitialStore) Lil.InitialStore = state;
        Lil.Store = state;
        return state;
    },
    useStore: () => {
        return useContext(LilContext);
    },
    resetStore: update => {
        Lil.Store = "reset";
        update(Lil.InitialStore);
    }
};

Lil.Account.login = async (user, password, callback = (result) => console.log("Logged in!", result)) => {
    const response = await fetch('/api/users/authenticate',{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({username: user, password: password})
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    Lil.Account.user = body;
    window.sessionStorage.authToken = JSON.stringify(body);
    callback(body);
};

Lil.Account.changePassword = async (user, password, newPassword, callback = (result) => console.log("Password changed!", result)) => {
    const response = await fetch('/api/users/change_password',{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({username: user, password: password, newPassword: newPassword})
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    callback(true);
};

Lil.Account.logout = () => {
    delete Lil.Account.user;
    delete window.sessionStorage.authToken;
};

Lil.method = async (methodName, params = {}, callback = (result) => console.log("Call Result: ", result)) => {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    if(Lil.Account.user) headers.Authorization = `Bearer ${Lil.Account.user.token}`
    const response = await fetch(`/api/methods/${methodName}`,{
        method: 'POST',
        headers,
        body: JSON.stringify(params)
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    callback(body);
};

Lil.DownloadPdf = (object, fieldToDownload, filename) => {
    const a = document.createElement("a"); //Create <a>
    a.href = 'data:application/octet-stream;base64,' + object[fieldToDownload];
    a.download = filename; //File name Here
    a.click(); //Downloaded file
};

if(window.sessionStorage.authToken){
    Lil.Account.user = JSON.parse(window.sessionStorage.authToken);
}

export default Lil;