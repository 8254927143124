import React from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography, Box } from '@material-ui/core';

// const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
// const days = ["", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];

// variable qui permet d'afficher la date du jour en français

// const formatDate = (date) => {
//     return days[date.getDay()] + " " + date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
// }

export default function Header(props) {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Box className={classes.header}>
            <Grid container spacing={0}>
                <Grid item xs={3} md={12}>
                    <Button className={classes.backButton} onClick={() => history.goBack()}><i className="fas fa-chevron-left" /></Button>
                </Grid>
                <Grid item xs={9} md={12}>
                    <Typography variant="h1" className={classes.title}>
                        {props.title}
                    </Typography>
                    {/* <Typography variant="h4" className={classes.date}>
                        {formatDate(new Date())}
                    </Typography> */}
                </Grid>
            </Grid>
        </Box >
    );
}

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: theme.palette.light.main
    },
    backButton: {
        width: 80,
        height: 50,
        margin: "20px 0 0 100px",
        [theme.breakpoints.down('sm')]: {
            margin: "10px 0 0 10px",
            height: 40,
            width: 60
        },
    },
    title: {
    //marginTop: "-60px",
        [theme.breakpoints.down('sm')]: {
            fontSize: 40,
            marginTop: 0
        },
    },
    date: {
        marginTop: 20,
        textAlign: 'center'
    }
}))