import React, { useState } from 'react';
import {
  ComposedChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Bar
} from 'recharts';

import { makeStyles, Grid, useTheme } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import CustomTooltip from './CustomTooltip';

export default function SimpleLineChartA7({ width, data, indexNames, barrieresDispo, couponDates }) {

  const theme = useTheme();
  const colors = theme.palette.customColors || [];
  const classes = useStyles();
  const [toggle, setToggle] = useState([]);
  const [barrieres, setBarrieres] = useState([]);

  // Determiner maxValue comme 10% de la valeur max des indexs du graph
  const maxValue = Math.max(...data.map(e => Math.max(...Object.entries(e).filter(([key, value]) => key !== 'year' && value !== undefined).map(([key, value]) => value))))
  const minValue = Math.min(...data.map(e => Math.min(...Object.entries(e).filter(([key, value]) => key !== 'year' && value !== undefined).map(([key, value]) => value))))
  const diff = maxValue - minValue;

  // Definition d'un objet "coupon" que l'on ajoute aux datas du graph
  if (couponDates) {
    let couponIndex = 0;
    let dataIndex = 0;

    while (couponIndex < couponDates.length && dataIndex < data.length) {
      if (couponDates[couponIndex] < data[dataIndex].year) {
        data.splice(dataIndex, 0, {year: couponDates[couponIndex], coupon: Math.ceil(minValue - diff/20)});
        couponIndex++;
      } else if (couponDates[couponIndex] === data[dataIndex].year) {
        data[dataIndex].coupon = Math.ceil(minValue - diff/20);
        couponIndex++;
      } else {
        dataIndex++;
      }
    }
  }

  data.sort((a, b) => a.year < b.year ? -1 : 1);

  console.log({ data });

  const switchToggle = (indexName) => {
    if (toggle.includes(indexName)) {
      setToggle(toggle.filter(name => name !== indexName));
    } else {
      setToggle(toggle.concat(indexName));
    }
  }

  const switchBarriere = (indexName) => {
    if (barrieres.includes(indexName)) {
      setBarrieres(barrieres.filter(name => name !== indexName));
    } else {
      setBarrieres(barrieres.concat(indexName));
    }
  }

  return (
    <>
      <Grid>
        <Grid>
          {data.length > 0 &&
            <ComposedChart width={width} height={600} data={data.map(e => ({ ...e, year: new Date(e.year).getTime() }))}>

              <CartesianGrid
                strokeDasharray="3 3"
              />

              <XAxis
                stroke={theme.palette.primary.main}
                tickLine={true}
                axisLine={true}
                // scale="time"
                type="number"
                dataKey="year"
                padding={{ left: 30, right: 30 }}
                domain={['dataMin', 'dataMax']}
                tickFormatter={(label) => new Date(label).toLocaleDateString()}
                tickCount={8}
                interval="preserveStartEnd"
              />
              <YAxis
                stroke={theme.palette.primary.main}
                tickLine={true}
                axisLine={true}
                domain={[Math.ceil(minValue - 0.1*diff), Math.floor(maxValue + 0.1*diff)]}
                tickFormatter={(value) => new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(value)}
                tickCount={8}
                interval="preserveStartEnd"
              />

              <Tooltip content={<CustomTooltip />} />

              {indexNames.map((indexName, id) =>
                indexName !== "coupon" &&
                <Line connectNulls key={id} type="linear" dataKey={indexName} stroke={!toggle.includes(indexName) ? colors[id] : "transparent"}
                  strokeWidth="4" activeDot={{ r: 0 }} dot={false}
                />
              )}
              {indexNames.map((indexName, id) =>
                indexName !== "coupon" &&
                <Line connectNulls key={"B" + id} type="linear" dataKey={"B" + indexName} stroke={(!toggle.includes(indexName) && !barrieres.includes("B" + indexName) && barrieresDispo.includes(indexName)) ? colors[id] : "transparent"}
                  strokeWidth="3" activeDot={{ r: 0 }} dot={false}
                />
              )}
              {indexNames.map((indexName, id) =>
                indexName !== "coupon" &&
                <Line connectNulls key={"R" + id} type="linear" dataKey={"R" + indexName}
                  stroke={(!toggle.includes(indexName) && !barrieres.includes("R" + indexName) && barrieresDispo.includes(indexName)) ? colors[id] : "transparent"}
                  strokeWidth="3" activeDot={{ r: 0 }} dot={false} strokeDasharray="5 5"
                />
              )}

              <Bar dataKey={"coupon"} fill="#000000" barSize={3} />

            </ComposedChart>
          }


        </Grid>
        <Grid className={classes.indexNamesBtnWrapper}>
          {indexNames.map((indexName, id) => indexName !== "coupon" && (
            <React.Fragment key={id}>
              <Button className={classes.whiteBtnLeft}
                style={toggle.includes(indexName) ?
                  { color: "grey", backgroundColor: "white" } : { color: "white", backgroundColor: colors[id] }}
                onClick={() => switchToggle(indexName)}>{indexName}</Button>
              <Button className={barrieresDispo.includes(indexName) ? classes.whiteBtnRight : classes.whiteBtnRightDisabled}
                disabled={!barrieresDispo.includes(indexName)}
                style={barrieresDispo.includes(indexName) ? (barrieres.includes("B" + indexName) || toggle.includes(indexName)) ?
                  { color: "grey", backgroundColor: "white" } : { color: "white", backgroundColor: colors[id] } : {}}
                onClick={() => switchBarriere("B" + indexName)}>+ protection</Button>
              <Button className={barrieresDispo.includes(indexName) ? classes.whiteBtnRight : classes.whiteBtnRightDisabled}
                disabled={!barrieresDispo.includes(indexName)}
                style={barrieresDispo.includes(indexName) ? (barrieres.includes("R" + indexName) || toggle.includes(indexName)) ?
                  { color: "grey", backgroundColor: "white" } : { color: "white", backgroundColor: colors[id] } : {}}
                onClick={() => switchBarriere("R" + indexName)}>+ remboursement</Button>
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    </>

  );
}

const useStyles = makeStyles(theme => ({
  whiteBtn: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.main,
    border: 'none',
    margin: "40px 40px 0 0",
    paddingTop: 0,
    width: "auto",
    height: 28,
    borderRadius: "10px",
    [theme.breakpoints.down('sm')]: {
      margin: 10,
      width: 80,
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white.main
    }
  },
  whiteBtnLeft: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.main,
    border: 'none',
    margin: "40px 0px 0 20px",
    padding: 10,
    width: "auto",
    height: 28,
    borderRadius: "10px 0 0 10px",
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white.main
    }
  },
  whiteBtnRight: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.main,
    border: 'none',
    margin: "40px -10px 0 0",
    padding: 10,
    width: "auto",
    height: 28,
    borderRadius: "0 10px 10px 0",
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white.main
    }
  },
  whiteBtnRightDisabled: {
    backgroundColor: theme.palette.light.main,
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    border: 'none',
    margin: "40px -8px 0 0",
    padding: 10,
    width: "auto",
    height: 28,
    borderRadius: "0 10px 10px 0",
    [theme.breakpoints.down('sm')]: {
      margin: 10,
      width: 80,
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white.main
    }
  },
  indexNamesBtnWrapper: {
    textAlign: "center",
    justifyContent: "center"
  }
}))

