import React from 'react';

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div style={{ backgroundColor: "#FFFFFF", border: "1px solid black", padding:"10px" }}>
        <p>{new Date(label).toLocaleDateString()}</p>
        {
          payload.map(e =>
              <p key={e.name} style={{ color: e.color }}>
                {e.name === "coupon" ? "Paiement de coupon" : e.name} 
                {e.name === "coupon" ? "" : ":"} 
                {e.name === "coupon" ? "" : e.value}</p>
          )
        }
      </div>
    );
  }
  return "";
}

export default CustomTooltip;